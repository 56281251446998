.line-text {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #232323;
  font-size: 12px;
  width: 100%;
}

.line-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1px;
  width: auto;
  background-color: rgb(41, 41, 41);
  margin-top: 10px;
  margin-bottom: 10px;
}
