.item {
  padding: 18px 12px;
  margin: 3px;
  font-family: "Roboto", sans-serif;
  /* width: 200px; */
  height: 10px;
  font-size: 18px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.item:hover {
  background-color: #ececec;
  border-radius: 4px;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
